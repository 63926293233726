import { useRef, useState, useContext, useMemo, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import moment from 'moment'

import {
	Button,
	Paper,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'

import { datesValues, nonDatesValues, operatorOptions } from 'utils/functions/conditionsOptions'
import { getAllColumnsByView } from './CreatViewComponents/ViewDialogUtils'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { useQuery } from '@tanstack/react-query'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
	printContainer: {
		display: 'none',
		'@media print': {
			display: 'block',
		},
	},
}))

const operatorMap = {
	EQ: '=',
	NE: '!=',
}

const PrintDataGrid = ({
	printTitle,
	gridColumns,
	rows,
	viewData,
	aggregationModel,
	rowGroupingModel,
	sortModel,
	filterModel,
}) => {
	const { t } = useTranslation()
	const { environment } = useSelector((state) => state)

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const {
		data: allColumns,
		isLoading,
		isFetching,
	} = useQuery({
		queryKey: ['print', 'getAllColumnsByView', viewData?.type],
		queryFn: () =>
			getAllColumnsByView({
				viewDataType: viewData?.type,
				apiToken: environment?.apiToken,
				formKey: viewData?.formKey,
			}),
		enabled: !!viewData?.type,
	})
	const printRef = useRef()
	const gridApiRef = useGridApiRef()
	const [openDialog, setOpenDialog] = useState(false)

	const [orientation, setOrientation] = useState('portrait')
	const [paperSize, setPaperSize] = useState('A4')
	const [showHeader, setShowHeader] = useState(true)

	const userOffset = useMemo(() => {
		return environment.userCurrent.time.timezone.offset || 0
	}, [environment])

	const operators = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	const dateValues = useMemo(() => {
		return datesValues(t)
	}, [t])

	function buildDateTimeValueByColumnType(value, columnType) {
		switch (columnType) {
			case 'datetime':
				return moment.utc(value).utcOffset(userOffset).format('MM/DD/YYYY HH:mm:ss')
			case 'date':
				return moment.utc(value).utcOffset(userOffset).format('MM/DD/YYYY')
			case 'time':
				return moment.utc(value).utcOffset(userOffset).format('HH:mm:ss')
			default:
				return value
		}
	}

	const nonDateValues = useMemo(() => {
		return nonDatesValues(t)
	}, [t])

	const queryString = useMemo(() => {
		if (isEmpty(viewData)) return ''

		const conditions = viewData?.queries[0]?.filter.conditions || []
		const query = conditions.map((condition) => {
			const { target, preset, type, values } = condition
			let column =
				allColumns?.find((column) => column.name === target) ||
				gridColumns?.find((gridColumn) => gridColumn.field === target)
			let field = column?.title || column?.headerName
			const columnType = column?.type?.toLowerCase()
			if (!field) {
				field = target
			}
			let ope = operatorMap[type]
			if (!ope) {
				ope = operators.find((item2) => item2.type === type)?.name
			}
			if (!ope) {
				ope = type
			}
			let val = ''
			if (preset) {
				val = dateValues?.find((item2) => item2.preset === preset)?.name
				if (!val) {
					val = nonDateValues?.find((item2) => item2.preset === preset)?.name
				}
				if (!val) {
					val = preset
				}
			} else if (values) {
				if (Array.isArray(values) && values.length > 1) {
					val =
						' [ ' +
						values
							?.map((value) => {
								return buildDateTimeValueByColumnType(value, columnType)
							})
							.join(' ; ') +
						' ]'
				} else {
					val = buildDateTimeValueByColumnType(values[0], columnType)
				}
			}
			return field + ' ' + ope + ' ' + val
		})

		if (query && query.length > 0) {
			let tmpFilter = query.filter((x) => x !== '')
			return tmpFilter?.join(', ')
		}

		if (!query) {
			return ' '
		}
	}, [viewData, gridColumns, allColumns])

	const [isPrinting, setIsPrinting] = useState(false)

	const promiseResolveRef = useRef(null)
	const [gridHeight, setGridHeight] = useState(undefined)

	useEffect(() => {
		if (gridApiRef.current) {
			gridApiRef.current.forceUpdate()
		}
	}, [rows])

	const adjustDataGridHeightForPrint = () => {
		const childElement = printRef.current.querySelector('.MuiDataGrid-root')

		const childHeight = childElement?.offsetHeight
		if (childHeight !== gridHeight) {
			setGridHeight(childHeight + 52)
		}

		const style = document.createElement('style')

		style.media = 'print'
		style.innerHTML = `
			@page {
				size: ${paperSize} ${orientation}; 
			}

			.MuiDataGrid-root{
				overflow: visible !important;
				max-height: none !important;		
				height: ${childElement.offsetHeight + 52}px !important
			}

			.MuiDataGrid-main,
			.MuiDataGrid-virtualScroller {
				overflow: visible !important;
				height: auto !important;
			}

			.MuiDataGrid-actionsCell {
				.MuiButtonBase-root
				{
					display: none !important;
				}
			}
		`
		document.head.appendChild(style)
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (isPrinting && promiseResolveRef.current) {
				adjustDataGridHeightForPrint()
				requestAnimationFrame(() => {
					if (promiseResolveRef.current) {
						promiseResolveRef.current()
					}
				})
			}
		}, 300)

		return () => clearTimeout(timeout)
	}, [isPrinting])

	const pageStyle = `
			@page {
				size: ${paperSize} ${orientation}; 
			}
		`

	const title = useMemo(() => {
		return printTitle || viewData?.name
	}, [printTitle, viewData])

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: title,
		pageStyle,
		onBeforePrint: () => {
			gridApiRef.current?.resize()
			return new Promise((resolve) => {
				setTimeout(() => {
					promiseResolveRef.current = resolve
					setIsPrinting(true)
				}, 1000)
			})
		},
		onAfterPrint: () => {
			promiseResolveRef.current = null
			setIsPrinting(false)
			setOpenDialog(false)
		},
	})

	return (
		<div>
			<MenuItem onClick={() => setOpenDialog(true)}>PDF</MenuItem>
			<Dialog
				sx={{
					'& .MuiDialog-paper': {
						width: '35% !important',
						maxWidth: '100% !important',
					},
				}}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
			>
				<DialogTitle>Print {printTitle || viewData?.name}</DialogTitle>
				<DialogContent>
					<p>Select your print options:</p>
					<FormControl fullWidth margin="dense">
						<InputLabel id="orientation-select-small-label">Orientation</InputLabel>
						<Select
							size="small"
							labelId="orientation-select-small-label"
							id="orientation-select-small"
							label={'Orientation'}
							value={orientation}
							onChange={(e) => setOrientation(e.target.value)}
						>
							<MenuItem value="portrait">Portrait</MenuItem>
							<MenuItem value="landscape">Landscape</MenuItem>
						</Select>
					</FormControl>
					<FormGroup style={{ marginLeft: '20px' }}>
						<FormControlLabel
							control={
								<Checkbox checked={showHeader} onChange={(e) => setShowHeader(e.target.checked)} />
							}
							label="Show header"
						/>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)} className={classes.icon}>
						{t('common:misc.cancel')}
					</Button>
					<LoadingButton
						className={classes.icon}
						style={{
							minWidth: '80px',
						}}
						disabled={isLoading || isFetching}
						loadingPosition="start"
						onClick={() => {
							handlePrint()
							setOpenDialog(false)
						}}
					>
						{t('common:misc.exportPrint')}
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<div className={classes.printContainer}>
				<Paper
					ref={printRef}
					style={{
						overflow: 'visible',
						display: 'block',
						width: '100%',
						height: 'auto',
						maxHeight: '999999px',
					}}
				>
					{showHeader && (
						<div style={{ borderBottom: '2px solid black', marginBottom: '10px' }}>
							<h2>{title}</h2>
							<div>{queryString}</div>
							<div>{`${environment.userCurrent.firstName} ${environment.userCurrent.lastName} - ${environment.userCurrent.email}`}</div>
							<div>
								{moment
									.utc()
									.utcOffset(userOffset || 0)
									.format('MM/DD/YYYY HH:mm:ss')}
							</div>
						</div>
					)}

					<div className={'gridContainer'}>
						<DataGridPremium
							sx={{
								'& .MuiDataGrid-footerContainer': {
									display: 'none !important',
								},
							}}
							apiRef={gridApiRef}
							rows={rows}
							columns={gridColumns}
							aggregationModel={aggregationModel}
							rowGroupingModel={rowGroupingModel}
							sortModel={sortModel}
							filterModel={filterModel}
							disableVirtualization={true}
							getRowHeight={() => 'auto'}
							defaultGroupingExpansionDepth={1}
							autoHeight
						/>
					</div>
				</Paper>
			</div>
		</div>
	)
}

export default PrintDataGrid
